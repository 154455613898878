import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import AddFavourite from "../../utility/js/addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/js/printer-details.js";
import materialMovementService from "./material-movement-service.js";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      lpnSearchValid: false,
      lpnNum: "",
      lpnData: [],
      showLPNDetails: false,
      lpnSearched: false,
      partsData: [],
      showLPNSearch: true,
      binDataItems: [],
      lpnBinDetailsDialog: false,
      searchBin: "",
      partsDetailsHeaders: [
        { text: "Part Number", align: "start", value: "part", class: "primary customwhite--text", width: "40%" },
        { text: "Quantity", value: "quantity", class: "primary customwhite--text", width: "30%" },
        { text: "View Details", value: "status", class: "primary customwhite--text", width: "30%" },
      ],
      binDataHeaders: [
        { text: "BCN", align: "start", value: "BCN" },
        { text: "Serial No", value: "Serial No" },
        { text: "ESN", value: "ESN" },
        { text: "License Plate", value: "License Plate" },
      ],
      searchLPNRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.$refs.refLPNNum.focus();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.lpnSearchValid = false;
      this.numofLPN = "";
      this.lpnData = [];
      this.showLPNDetails = false;
      this.lpnSearched = false;
      this.lpnNum = "";
      this.partsData = [];
      this.showLPNSearch = false;
      this.showLPNSearch = true;
      this.binDataItems = [];
      this.lpnBinDetailsDialog = false;
      this.searchBin = "";
      this.$refs.refLPNNum.focus();
      this.$refs.lpnDetailsForm.resetValidation();
    },
    //Create Label based on number of Labels entered
    searchLPN() {
      if (this.$refs.lpnDetailsForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let searchLPN = {
          user_id: parseInt(this.userId),
          lpn: this.lpnNum,
          pass: 2,
        };
        this.axios
          .post("/mm/lpn_details", searchLPN)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let responsedata = JSON.parse(response.data.body.message);
              if (responsedata?.Result !== undefined && responsedata?.Result !== null) {
                this.lpnData = [];
                if (responsedata?.Result[0]?.isActivity) {
                  const newArr = responsedata?.Result.map((obj) => {
                    return {
                      ...obj,
                      LastActivity: Utility.convertESTToLocal(obj["LastActivity"]),
                    };
                  });
                  this.lpnData = newArr;
                } else {
                  this.lpnData = responsedata?.Result;
                  this.lpnData[0].LastActivity = "NO ACTIVITY";
                }
                if (this.lpnData[0].Parts !== undefined) {
                  this.partsData = this.lpnData[0].Parts.split("|").map((item) => {
                    const [partQuantity, status] = item.trim().split(";");
                    const [part, quantity] = partQuantity.trim().split(" (");
                    return {
                      part: part.trim(),
                      quantity: quantity.replace(" pcs)", "").trim(),
                      status: status.trim(),
                    };
                  });
                }
                this.showLPNDetails = true;
                this.lpnSearched = true;
              } else {
                this.lpnData = [];
                this.showLPNDetails = false;
                this.lpnSearched = false;
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "No Data for the LPN",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //View Allocation Details
    async viewBinDetails(part) {
      let lpnData = {
        user_id: parseInt(this.userId),
        lpn: this.lpnNum,
        part_num: part,
      };
      let showMsg = false;
      let data = await materialMovementService.lpnPartDetails("post", lpnData, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.binDataItems = data?.Result;
          this.lpnBinDetailsDialog = true;
        }
      }
    },
    //Popup close
    closeDialog() {
      this.binDataItems = [];
      this.searchBin = "";
      this.lpnBinDetailsDialog = false;
    },
    //to check if its a number of not
    lpnNumFormat(event, num) {
      this.lpnNum = Utility.formatLPNNumber(event, num);
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
